import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import USP from "../components/home/usp";
import Button from "../components/buttons/button";

import driversOne from "../images/drivers/motorcycle.png";
import driversTwo from "../images/drivers/light-motor-vehicle.png";
import driversThree from "../images/drivers/heavy-motor-vehicle.png";
import nubeecarImage from "../images/home/nubee-car.png";



function DriversPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Nubee`, `driving school`, `learners`, `artificial intelligence`]}
        title="Learners"
      />

<section className="md:p-0 text-left mt-28 md:mt-18">
        <div className="flex flex-col md:flex-row gap-10 md:gap-32 items-center px-6 md:max-w-7xl mx-auto">
          <img className="w-3/4 md:w-2/5 order-last md:order-first" src={nubeecarImage}/>
          <div>
            <h1 className="mb-4 text-5xl md:text-6xl font-bold text-black font-heading">
              Get your licence. 
            </h1>
            <p className="text-black">
            Nubee offers a hassle-free way to learn how to drive and get your {"driver's"} licence. Signing up and starting to learn with Nubee is a breeze. The platform offers a user-friendly interface and a range of interactive and engaging course materials designed to help you acquire the skills and knowledge you need to become a safe and confident driver. With Nubee, you can learn at your own pace and on your own schedule, whether {"you're"} a beginner or looking to refresh your skills. Book your first lesson with an experienced driving instructor who will help you prepare and pass your driving test with confidence.
            </p>
            <Button title="Get Started" href="https://app.nubee.co.za/#/auth/registration/personal-info"></Button>
          </div>
        </div>
      </section>

      {/* <section className="md:p-0 text-left pt-28 md:pt-24">
        <div className="px-6 md:max-w-7xl mx-auto">
          <div className="md:pr-2">
            <div>
              <h1 className="inline-block mb-4 text-5xl md:text-6xl font-bold text-black font-heading">
                What do you want to learn?
              </h1>
              <p className="py-8 text-black font-body max-w-xl">Before we get you behind the wheel, you need to decide what type of licence you want.</p>
            </div>

            <div>
                <form className="flex flex-row gap-5">
                <div className="form-check">
                    <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked></input>
                    <label className="form-check-label inline-block text-gray-800">
                        Manual
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault1"></input>
                    <label className="form-check-label inline-block text-gray-800">
                        Automatic
                    </label>
                </div>
                </form>
            </div>

            <div className="mt-14">
                <form className="flex flex-col md:flex-row gap-16 md:gap-24 justify-center">
                <div className="form-check">
                    <img className="w-full" src={driversOne}/>
                        <div className="mt-8">
                        <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked></input>
                        <label className="form-check-label inline-block text-gray-800">
                            Code A: Motorcycle
                        </label>
                    </div>
                </div>
                <div className="form-check">
                    <img className="w-full" src={driversTwo}/>
                        <div className="mt-8">
                        <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault1"></input>
                        <label className="form-check-label inline-block text-gray-800">
                            Code 8: Light Motor Vehicle
                        </label>
                    </div>
                </div>
                <div className="form-check">
                    <img className="w-full" src={driversThree}/>
                        <div className="mt-8">
                        <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault1"></input>
                        <label className="form-check-label inline-block text-gray-800">
                            Code 10: Heavy Motor Vehicle
                        </label>
                    </div>
                </div>
                </form>
            </div>
          </div>
          
        </div>
      </section> */}

      {/* <section className="px-6 md:pr-16 py-3 md:pt-10">
        <div className="text-right">
        <Button title="Continue" href="/drivers-instructor-selection"></Button>
        </div>
      </section> */}
    </Layout>
  );
}

export default DriversPage;
